import Gallery from '@browniebroke/gatsby-image-gallery'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import * as styles from '../../assets/css/history.module.css'
import Layout from '../../components/Layout'
import SmartLink from '../../components/SmartLink'

const essayQuery = graphql`
  query {
    content(uid: { eq: "c5" }) {
      headline
      subline
      byline
      beach {
        childImageSharp {
          thumb: gatsbyImageData(width: 500, height: 375, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
      slides {
        childImageSharp {
          thumb: gatsbyImageData(width: 500, height: 375, placeholder: BLURRED)
          full: gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`

const SeasideHistory = () => {
  const { content } = useStaticQuery(essayQuery)
  return (
    <Layout>
      <section>
        <h1 className={styles.historyHeadline}>{content.headline}</h1>
        <h2 className={styles.historySubhead}>{content.subline}</h2>
        <section>
          <div className={styles.historyContent}>
            <div className={styles.historyAboutImg}><Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.beach.childImageSharp]}
              /></div>
            <div>
              <SmartLink to='/essays/visions-of-seaside'><h4>Visions of Seaside 1946-2011</h4></SmartLink>
              <div className={styles.historyNote}><h4>By Robert Davis</h4></div>
              <div className={styles.historyNote}><h4>Founder, Seaside Florida</h4></div>
            </div>

            <div className={styles.historyAboutImg}><Gallery
                colWidth='100'
                mdColWidth='100'
                gutter='0'
                rowMargin='0'
                images={[content.slides.childImageSharp]}
              /></div>
            <div>
              <SmartLink to='/essays/the-artfulness-of-community-making'><h4>The Artfulness of Community Making</h4></SmartLink>
              <div className={styles.historyNote}><h4>By Daryl Rose Davis</h4></div>
              <div className={styles.historyNote}><h4>Founder, Seaside Florida</h4></div>
            </div>
          </div>
        </section>

      </section>
    </Layout>
  )
}

export default SeasideHistory
